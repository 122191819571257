/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    h3: "h3",
    code: "code",
    hr: "hr"
  }, _provideComponents(), props.components), {Type, ParamTable, Params} = _components;
  if (!ParamTable) _missingMdxReference("ParamTable", true);
  if (!Params) _missingMdxReference("Params", true);
  if (!Type) _missingMdxReference("Type", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "overview",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#overview",
    "aria-label": "overview permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "Overview"), "\n", React.createElement(_components.p, null, "The Controller component parses the request into the proper index\nand propagates it to the Move component."), "\n", React.createElement(_components.h2, {
    id: "methods",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#methods",
    "aria-label": "methods permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "Methods"), "\n", React.createElement(_components.h3, {
    id: "go",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#go",
    "aria-label": "go permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "go()")), "\n", React.createElement(Type, {
    code: 'go( control: number | string, allowSameIndex?: boolean ): void'
  }), "\n", React.createElement(_components.p, null, "Moves the slider by the following control pattern. ", React.createElement(_components.code, null, "Splide#go()"), " is the alias of this method."), "\n", React.createElement(ParamTable, {
    params: [['i', 'Goes to the slide `i`'], [`'+\${i}'`, 'Increments the slide index by `i`'], [`'-\${i}'`, 'Decrements the slide index by `i`'], [`'>'`, 'Goes to the next page'], [`'<'`, 'Goes to the previous page'], [`'>\${i}'`, 'Goes to the page `i`']]
  }), "\n", React.createElement(Params, {
    params: [['control', 'A control pattern.'], ['allowSameIndex', 'Optional. Determines whether to allow to go to the current index or not.'], ['callback', 'Optional. A callback function invoked after transition ends.']]
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "scroll",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#scroll",
    "aria-label": "scroll permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "scroll()")), "\n", React.createElement(Type, {
    code: 'scroll( destination: number, useIndex?: boolean, snap?: boolean, duration?: number, callback?: AnyFunction ): void'
  }), "\n", React.createElement(_components.p, null, "Scrolls the slider to the specified destination or index with updating current and previous indices."), "\n", React.createElement(Params, {
    params: [['destination', 'A position or an index to scroll to. The `useIndex` must be `true` to specify the index.'], ['useIndex', 'Optional. Whether to use an index as a `destination` or not.'], ['snap', 'Optional. Whether to snap the closest slide or not.'], ['duration', 'Optional. Specifies the scroll duration. If omitted, it is computed by distance.'], ['callback', 'Optional. A callback function invoked after scroll ends.']]
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "getnext",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#getnext",
    "aria-label": "getnext permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "getNext()")), "\n", React.createElement(Type, {
    code: 'getNext( destination?: boolean ): number'
  }), "\n", React.createElement(_components.p, null, "Returns a next index. By default, this method resolves the clone index to the real slide index -\nfor example, ", React.createElement(_components.code, null, "-1"), " will be ", React.createElement(_components.code, null, "length - 1"), ", but if the ", React.createElement(_components.code, null, "destination"), " is ", React.createElement(_components.code, null, "true"), ", the method returns ", React.createElement(_components.code, null, "-1"), " as it is."), "\n", React.createElement(_components.p, null, "This method can also return ", React.createElement(_components.code, null, "-1"), " even if the ", React.createElement(_components.code, null, "destination"), " is ", React.createElement(_components.code, null, "false"), ",\nwhich means there is no next slide to go."), "\n", React.createElement(Params, {
    params: [['destination', 'Optional. Determines whether to get a destination index or a slide index.']],
    ret: 'A next index if available, or otherwise `-1`.'
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "getprev",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#getprev",
    "aria-label": "getprev permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "getPrev()")), "\n", React.createElement(Type, {
    code: 'getPrev( destination?: boolean ): number'
  }), "\n", React.createElement(_components.p, null, "Returns a previous index. By default, this method resolves the clone index to the real slide index -\nfor example, ", React.createElement(_components.code, null, "-1"), " will be ", React.createElement(_components.code, null, "length - 1"), ", but if the ", React.createElement(_components.code, null, "destination"), " is ", React.createElement(_components.code, null, "true"), ", it does not."), "\n", React.createElement(_components.p, null, "This method can also return ", React.createElement(_components.code, null, "-1"), " even if the ", React.createElement(_components.code, null, "destionation"), " is ", React.createElement(_components.code, null, "false"), ",\nwhich means there is no previous slide to go."), "\n", React.createElement(Params, {
    params: [['destination', 'Optional. Determines whether to get a destination index or a slide index.']],
    ret: 'A previous index if available, or otherwise `-1`.'
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "getend",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#getend",
    "aria-label": "getend permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "getEnd()")), "\n", React.createElement(Type, {
    code: 'getEnd(): number'
  }), "\n", React.createElement(_components.p, null, "Returns the end index where the slider can go.\nFor example, if the slider has 10 slides and the ", React.createElement(_components.code, null, "perPage"), " option is ", React.createElement(_components.code, null, "3"), ",\nthe slider can go to the slide 8 (the index is 7)."), "\n", React.createElement(Params, {
    ret: 'An end index.'
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "setindex",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#setindex",
    "aria-label": "setindex permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "setIndex()")), "\n", React.createElement(Type, {
    code: 'setIndex( index: number ): void'
  }), "\n", React.createElement(_components.p, null, "Updates the current index.\nThis method just updates the internal variable and nothing happens to the slider."), "\n", React.createElement(Params, {
    ret: 'A new index to set.'
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "getindex",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#getindex",
    "aria-label": "getindex permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "getIndex()")), "\n", React.createElement(Type, {
    code: 'getPrev( prev?: boolean ): number'
  }), "\n", React.createElement(_components.p, null, "Returns the current or previous index. ", React.createElement(_components.code, null, "Splide#index"), " is the alias of the ", React.createElement(_components.code, null, "Controller#getIndex()"), "."), "\n", React.createElement(Params, {
    params: [['prev', 'Optional. Determines whether to return previous (old) index or not.']]
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "toindex",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#toindex",
    "aria-label": "toindex permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "toIndex()")), "\n", React.createElement(Type, {
    code: 'toIndex( page: number ): number'
  }), "\n", React.createElement(_components.p, null, "Converts the page index to the slide index."), "\n", React.createElement(Params, {
    params: [['page', 'A page index to convert.']],
    ret: 'A slide index.'
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "topage",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#topage",
    "aria-label": "topage permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "toPage()")), "\n", React.createElement(Type, {
    code: 'toPage( index: number ): number'
  }), "\n", React.createElement(_components.p, null, "Converts the slide index to the page index."), "\n", React.createElement(Params, {
    params: [['page', 'An index to convert.']],
    ret: 'A page index.'
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
